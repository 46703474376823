import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { FaSearchLocation, FaPhone, FaEnvelopeOpenText } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				Contact | До Самого Моря
			</title>
			<meta name={"description"} content={"Кожна подорож заслуговує на унікальний початок"} />
			<meta property={"og:title"} content={"Contact | До Самого Моря"} />
			<meta property={"og:description"} content={"Кожна подорож заслуговує на унікальний початок"} />
			<meta property={"og:image"} content={"https://blackseabeaches.live/img/people_3.3.jpg"} />
			<link rel={"shortcut icon"} href={"https://blackseabeaches.live/img/3077421.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://blackseabeaches.live/img/3077421.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://blackseabeaches.live/img/3077421.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://blackseabeaches.live/img/3077421.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://blackseabeaches.live/img/3077421.png"} />
			<meta name={"msapplication-TileImage"} content={"https://blackseabeaches.live/img/3077421.png"} />
			<meta name={"msapplication-TileColor"} content={"https://blackseabeaches.live/img/3077421.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--primary"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					as="h1"
				>
					Зв'яжіться з нами
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaSearchLocation}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Адреса
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--lead"
						lg-text-align="left"
						flex="1 0 auto"
					>
						вул. Миколи Закревського 24, м. Київ, 02217
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaPhone}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Телефон
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--lead"
						lg-text-align="left"
						flex="1 0 auto"
					>
						+38 095-240-14-62
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Icon
						category="fa"
						icon={FaEnvelopeOpenText}
						margin="0px 0px 30px 0px"
						color="--dark"
						size="48px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Email
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--lead"
						lg-text-align="left"
						flex="1 0 auto"
					>
						admin@blackseabeaches.live
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});